import { ApplicationRef, DestroyRef, inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { VersionReadyEvent } from '@angular/service-worker';
import { SwUpdate } from '@angular/service-worker';
import { ReplaySubject, concat, filter, first, interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromptUpdateService {
  destroyRef = inject(DestroyRef);
  appRef = inject(ApplicationRef);
  updates = inject(SwUpdate);
  updateApp$ = new ReplaySubject<boolean>(1); // ReplaySubject so it caches first emmit before subscription

  checkForUpdates() {
    return () => {
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const checkForUpdateInterval$ = interval(3 * 60 * 60 * 1000); //every 3 hours
      const checkForUpdateIntervalOnceAppIsStable$ = concat(appIsStable$, checkForUpdateInterval$);

      checkForUpdateIntervalOnceAppIsStable$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async () => {
          try {
            const updateFound = await this.updates.checkForUpdate();
            console.log(
              updateFound ? 'A new version is available.' : 'Already on the latest version.'
            );
          } catch (err) {
            console.error('Failed to check for updates:', err);
          }
        });

      this.updates.versionUpdates
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
        )
        .subscribe(evt => {
          if (evt) this.updateApp$.next(true);
        });

      this.updates.unrecoverable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
        console.log(
          'An error occurred while updating\n' + event.reason + '\n\nPlease reload the page.'
        );
        this.updateApp$.next(true);
      });
    };
  }
}
