import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authenticated) {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const paramValue = params.get('kc_idp_hint');
      await this.keycloak.login({ idpHint: paramValue ?? '' });
    }

    return true;
  }
}

export const SessionGuard: CanActivateFn = () => {
  const session = !!window.sessionStorage.getItem('poolSession');
  if (session) {
    return false;
  }
  return true;
};
