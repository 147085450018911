<div class="align-center">
  <img src="/assets/bg/404.svg" />

  <div class="my-10">
    <ui-headline> Leider konnten wir hierzu nichts finden! </ui-headline>
  </div>

  <div class="my-10">
    <a routerLink="/" mat-stroked-button>Zurück zur Startseite</a>
  </div>
</div>
