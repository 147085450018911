import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import type { InterfaceComponentLinksMenu } from '@comparit/core';
import {
  CustomIconsService,
  HeaderComponent,
  KeycloakAuthService,
  PromptUpdateService,
  UpdateAppModalComponent,
} from '@comparit/core';
import { combineLatest, filter, from, map, switchMap, take } from 'rxjs';
import type { RouteString } from './routes.model';
import { AvatarUserComponent } from './page-login/avatar-user/avatar-user.component';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StateService } from '../services/state.service';
import { ProduktService, SparteService } from '../ti-interfaces';

const HEADER_LINKS: InterfaceComponentLinksMenu<RouteString>[] = [
  {
    url: '/insurers',
    title: 'Versicherer',
  },
  {
    url: '/tarifbereiche',
    title: 'Tarifbereiche',
  },
  {
    url: '/tariffs',
    title: 'Tarife',
  },
  {
    url: '/attributes',
    title: 'Attribute',
  },
  {
    url: '/berufelisten',
    title: 'Berufelisten',
  },
  {
    url: '/fondslisten',
    title: 'Fondslisten',
  },
];

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, AvatarUserComponent, MatDialogModule],
  selector: 'comparit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private _promptUpdateService = inject(PromptUpdateService);
  private _customIconsService = inject(CustomIconsService);
  private _sparte = inject(SparteService);
  private _produkt = inject(ProduktService);
  private _state = inject(StateService);
  private _matDialog = inject(MatDialog);

  keycloakService = inject(KeycloakService);
  keycloakAuthService = inject(KeycloakAuthService);

  headerLogo$ = combineLatest([this._state.selectedSparte$, this._state.selectedProdukt$]).pipe(
    filter(
      ([sparteId, produktId]) =>
        sparteId != null && sparteId.trim() != '' && produktId != null && produktId.trim() != ''
    ),
    switchMap(([sparteId, produktId]) =>
      combineLatest([
        this._sparte.getSparte({ sparteId }),
        this._produkt.getProdukt({ produktId }),
      ]).pipe(
        filter(([sparte, produkt]) => sparte !== null && produkt !== null),
        map(([sparte, produkt]) => ({
          sparte: sparte?.name?.value,
          produkt: produkt?.name?.value,
        }))
      )
    )
  );

  title = 'Tarifingress';

  links$ = from(this.keycloakService.isLoggedIn()).pipe(
    map(loggedIn => (loggedIn ? HEADER_LINKS : []))
  );

  constructor() {
    this._promptUpdateService.updateApp$.pipe(take(1)).subscribe(() => {
      this._matDialog
        .open(UpdateAppModalComponent, { data: { appName: 'TI' }, disableClose: true })
        .afterClosed()
        .subscribe(() => {
          window.location.reload();
        });
    });

    this._customIconsService.init();
  }
}
