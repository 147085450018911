import type { IsActiveMatchOptions } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export interface InterfaceComponentLinksMenu<URLs = string> {
  url: URLs;
  title: string;
}

export const LINK_MATCHER: IsActiveMatchOptions = {
  paths: 'subset',
  fragment: 'ignored',
  matrixParams: 'ignored',
  queryParams: 'ignored',
};

@Component({
  selector: 'ui-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule],
})
export class HeaderComponent {
  routerLinkActiveOptions = LINK_MATCHER;

  trackById = (i: number) => i;

  @Input() links?: InterfaceComponentLinksMenu[] | null = [];
}
