/**
 * TarifIngress
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ListBerufIdsColumn {
    BezeichnungM = 'BezeichnungM',
    BezeichnungW = 'BezeichnungW',
    SynonymM = 'SynonymM',
    SynonymW = 'SynonymW',
    Schluessel = 'Schluessel',
    Berufsgruppe = 'Berufsgruppe'
}

