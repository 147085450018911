import { Injectable } from '@angular/core';
import type { AbstractControl, ValidatorFn } from '@angular/forms';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === 'string') {
      let day, month, year;

      if (value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/)) {
        const [dayStr, monthStr, yearStr] = value.split('.');
        day = Number(dayStr);
        month = Number(monthStr);
        year = Number(yearStr);
      } else if (value.match(/^\d{8}$/)) {
        day = Number(value.substring(0, 2));
        month = Number(value.substring(2, 4));
        year = Number(value.substring(4, 8));
      } else {
        return null;
      }

      if (!isValidDate(day, month, year)) {
        return null;
      }

      return new Date(year, month - 1, day);
    }
    return null;
  }

  override format(date: Date, displayFormat: any): string {
    if (date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }
    return super.format(date, displayFormat);
  }
}

export function isValidDate(day: number, month: number, year: number): boolean {
  if (month < 1 || month > 12 || day < 1 || year.toString().length !== 4) {
    return false;
  }

  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  const monthLengths = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return day <= monthLengths[month - 1];
}

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    return !control.value ? { date: { value: control.value } } : null;
  };
}

export function maxDateValidator(maxDate: Date): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) return null;
    const date = new Date(control.value);
    return date > maxDate ? { maxDate: { value: maxDate } } : null;
  };
}

export function minDateValidatorFactory(errorKey: string, minDate: Date): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) return null;
    const date = new Date(control.value);
    return date < minDate ? { [errorKey]: { value: minDate } } : null;
  };
}

export function minDateValidator(minDate: Date): ValidatorFn {
  return minDateValidatorFactory('minDate', minDate);
}

export function minStartOfContractDateValidator(minDate: Date): ValidatorFn {
  return minDateValidatorFactory('minStartOfContractDate', minDate);
}
