import type { TrackByFunction } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import type { Observable } from 'rxjs';
import { BehaviorSubject, ReplaySubject, combineLatest, map, of, startWith, take } from 'rxjs';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSelectModule } from '@angular/material/select';
import type { AbstractControl } from '@angular/forms';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
import { MapPipe } from '../../pipes/map.pipe';

const MAX_SELECT_OPTIONS_WITHOUT_SEARCH = 5;

type possibleTypes = boolean | string | number;

interface SelectOption extends Option<possibleTypes> {
  visible: boolean;
}

type IReturnType = possibleTypes | Array<possibleTypes>;

@Component({
  selector: 'ui-select',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    OverlayModule,
    ReactiveFormsModule,
    MapPipe,
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends ControlValueAccessorDirective<IReturnType> {
  trackByValue: TrackByFunction<SelectOption> = (_, c) => c.value;
  searchQuery = new FormControl<string>('');
  label$ = new ReplaySubject<string>(1);
  filterInput$ = new BehaviorSubject<boolean>(true);
  options$ = new BehaviorSubject<Option<possibleTypes>[]>([]);
  multiSelect$ = new ReplaySubject<boolean>(1);
  searchQuery$ = new BehaviorSubject<string | null>('');
  searchQueryResettable$: Observable<boolean> = this.searchQuery$.pipe(map(sq => !!sq));
  optionsWithVisibilityStatus$: Observable<SelectOption[]> = combineLatest([
    this.options$,
    this.searchQuery$,
  ]).pipe(
    map(([options, searchQuery]) => {
      const filteredOptions = searchQuery
        ? options.map(o => ({
            ...o,
            visible: o.label.toLowerCase().includes(searchQuery.toLowerCase()),
          }))
        : options.map(o => ({ ...o, visible: true }));

      return filteredOptions;
    })
  );
  filteredOptions$: Observable<Option<possibleTypes>[]> = this.optionsWithVisibilityStatus$.pipe(
    map(options => options.filter(o => o.visible))
  );

  searchable$: Observable<boolean> = this.options$.pipe(
    map(options => options.length > MAX_SELECT_OPTIONS_WITHOUT_SEARCH)
  );

  allSelected$ = new BehaviorSubject(false);

  @Input()
  set options(options: Option<possibleTypes>[] | undefined | null) {
    this.options$.next(options || []);
    // we need to reset the searchQuery otherwise we might not be able to reset it
    // when the options change to a list with less than 6 options
    this.searchQuery.reset();
  }

  @Input()
  set filterInput(filterInput: boolean) {
    this.filterInput$.next(filterInput);
  }

  @Input()
  set multiSelect(multiSelect: boolean) {
    this.multiSelect$.next(multiSelect);
  }

  @Input()
  set query(query: string) {
    this.searchQuery.setValue(query);
  }

  @Input()
  set label(label: string) {
    this.label$.next(label);
  }

  get required() {
    const form_field = this.control;
    if (!form_field.validator) {
      return false;
    }

    //  Return the required state of the validator
    const validators = form_field.validator({} as AbstractControl) || {};
    return validators && validators['required'];
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.searchQuery.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(searchQuery => this.searchQuery$.next(searchQuery));

    combineLatest([
      this.filteredOptions$,
      this.control.valueChanges.pipe(startWith(this.control.value)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([options, value]) => {
        this.allSelected$.next(
          options.every(option => value?.toString().includes(option?.value?.toString()))
        );
      });
  }

  selectAll() {
    combineLatest([this.filteredOptions$, of(this.control.value)])
      .pipe(take(1))
      .subscribe(([filteredOptions, value]) => {
        if (typeof value === 'string' || typeof value === 'number') {
          return;
        }
        const filteredValue = filteredOptions.map(option => option.value);
        const newValues = filteredValue.filter(fv => !value?.toString().includes(fv?.toString()));
        this.writeValue([...newValues, ...((value || []) as possibleTypes[])] as IReturnType);
      });
  }

  deselectAll() {
    combineLatest([this.filteredOptions$, of(this.control.value)])
      .pipe(take(1))
      .subscribe(([filteredOptions, value]) => {
        if (typeof value === 'string' || typeof value === 'number') {
          return;
        }
        const filteredValue = filteredOptions.map(option => option.value);
        const newValue = ((value as possibleTypes[]).filter(v => !filteredValue.includes(v)) ||
          []) as IReturnType;
        this.writeValue(newValue);
      });
  }

  clearSearchQuery() {
    this.searchQuery.reset();
  }
}

export interface Option<T> {
  label: string;
  value: T;
}

export function createOptionsByEnum<T>(data: { [key: string]: T }): Option<T>[] {
  return Object.keys(data).map(key => {
    return {
      label: key,
      value: data[key],
    } satisfies Option<T>;
  });
}

export function getLabelByEnumOption<T>(value: T, enumArray: Option<T>[]): string | undefined {
  const result = enumArray.find(x => x.value === value);
  return result ? result.label : '';
}
