/**
 * TarifIngress
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AttributDataType {
    Boolean = 'Boolean',
    Number = 'Number',
    String = 'String',
    List = 'List',
    Range = 'Range',
    RangeExtended = 'RangeExtended'
}

