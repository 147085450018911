import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { HeadlineComponent } from '../headline/headline.component';

@Component({
  selector: 'ui-content-404',
  standalone: true,
  imports: [CommonModule, HeadlineComponent, MatButtonModule, RouterLink],
  templateUrl: './content-404.component.html',
  styleUrls: ['./content-404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Content404Component {}
