<ng-template #resetBtn>
  <button
    tabindex="-1"
    mat-button
    (click)="setDefaultCheckedItems()"
    [disabled]="control.value | map: isDefaultValue"
  >
    Auswahl zurücksetzen
  </button>
</ng-template>
<ng-template #showOnlyCheckedBtn>
  <button tabindex="-1" mat-button (click)="toggleShowOnlyChecked()">
    {{ (showOnlyChecked$ | async) ? 'Alle anzeigen' : 'Nur ausgewählte anzeigen' }}
  </button>
</ng-template>
<ng-template #toggleAllBtn>
  <button tabindex="-1" mat-button (click)="toggleAll()">
    {{ (toggleAll$ | async) ? 'Alle zuklappen' : 'Alle aufklappen' }}
  </button>
</ng-template>

<div *ngIf="control && valueFormGroup$ | async as fg">
  <!-- <pre>{{control.value | json}}</pre>
    <pre>{{fg.value | json}}</pre> -->

  <mat-form-field>
    <div class="flex gap-4">
      <mat-icon svgIcon="cp-search"></mat-icon>
      <input matInput [formControl]="searchQuery" />
      <mat-icon
        *ngIf="searchQueryResettable$ | async"
        svgIcon="cp-close"
        (click)="this.searchQuery.reset()"
      ></mat-icon>
    </div>
  </mat-form-field>

  <div class="w-full my-3 flex justify-between items-center">
    <ng-container *ngTemplateOutlet="showOnlyCheckedBtn"></ng-container>
    <ng-container *ngTemplateOutlet="resetBtn"></ng-container>
    <ng-container *ngTemplateOutlet="toggleAllBtn"></ng-container>
  </div>

  <cdk-virtual-scroll-viewport class="selection-list" itemSize="62">
    <div class="text-center text-muted" *ngIf="(showItemsCount$ | async) === 0">
      Keine Einträge gefunden
    </div>
    <ng-container *cdkVirtualFor="let item of items$ | async; trackBy: trackByValue">
      <!-- if parent -->
      <div
        class="drag-item"
        *ngIf="
          item.childrenCtrls && {
            checked: item.childrenCtrls | map: toValues$ | async | map: checkAllTrue,
            indeterminate: item.childrenCtrls | map: toValues$ | async | map: checkOneTrue
          } as tmp
        "
        [class.active]="tmp.checked"
      >
        <div class="flex justify-between items-center">
          <mat-checkbox
            [indeterminate]="tmp.indeterminate && !tmp.checked"
            [checked]="tmp.checked"
            (change)="markAllChildren($event, item.childrenCtrls)"
            [disabled]="!item.childrenCtrls.length"
          >
            {{ item.label }}
            ({{ item.childrenCtrls.length }})
          </mat-checkbox>

          <mat-icon
            class="cursor-pointer"
            *ngIf="(showOnlyChecked$ | async) === false"
            (click)="item.toggle$.next(!item.toggle$.value)"
            [class.white-icon]="tmp.checked"
            [svgIcon]="(item.toggle$ | async) ? 'cp-direction-up' : 'cp-direction-down'"
          ></mat-icon>
        </div>
      </div>

      <!-- if children -->
      <div class="drag-item ml-10" *ngIf="item.ctrl" [class.active]="item.ctrl.value">
        <mat-checkbox [checked]="item.ctrl.value" (change)="markControl($event, item.ctrl)">
          {{ item.label }}
        </mat-checkbox>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
