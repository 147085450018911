import type { CanActivateFn, Route } from '@angular/router';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { ToastService } from '@comparit/core';
import { StateService } from '../services/state.service';

export const sparteGuardWithRedirect = (redirectRoute: Route) => {
  const resp: CanActivateFn = () => {
    const stateService = inject(StateService);
    const router = inject(Router);
    const toast = inject(ToastService);

    if (!stateService.selectedSparte || !stateService.selectedProdukt) {
      toast.show('Please select a Produkt and a Sparte');
      router.navigate([redirectRoute.path], {
        skipLocationChange: true,
      });
      return of(false);
    }

    return of(true);
  };
  return resp;
};
