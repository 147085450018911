/**
 * TarifIngress
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AbschlussEnum {
    KeinAbschluss = 'Kein_Abschluss',
    Hauptschulabschluss = 'Hauptschulabschluss',
    QualifizierterHauptschulabschluss = 'Qualifizierter_Hauptschulabschluss',
    MittlereReife = 'MittlereReife',
    Fachabitur = 'Fachabitur',
    Abitur = 'Abitur',
    BachelorFachhochschule = 'Bachelor_Fachhochschule',
    BachelorUniversitaet = 'Bachelor_Universitaet',
    MasterFachhochschule = 'Master_Fachhochschule',
    MasterUniversitaet = 'Master_Universitaet',
    DiplomFachhochschule = 'Diplom_Fachhochschule',
    DiplomMagister = 'Diplom_Magister',
    StaatsexamenUniversitaet = 'Staatsexamen_Universitaet',
    Promotion = 'Promotion',
    Meister = 'Meister',
    Techniker = 'Techniker',
    Fachschule = 'Fachschule',
    Hochschulabschluss = 'Hochschulabschluss',
    KaufmaennischeAusbildung = 'KaufmaennischeAusbildung',
    HandwerklicheAusbildung = 'HandwerklicheAusbildung',
    SonstigeAusbildung = 'SonstigeAusbildung',
    WeiterbildungzumTechniker = 'WeiterbildungzumTechniker',
    WeiterbildungzumFachBetriebswirt = 'WeiterbildungzumFachBetriebswirt'
}

