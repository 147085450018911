<header class="flex items-stretch justify-between space-x-4">
  <ng-content select="[logo]"></ng-content>

  <ul>
    <li *ngFor="let link of links; trackBy: trackById">
      <a
        [routerLink]="link.url"
        [routerLinkActiveOptions]="routerLinkActiveOptions"
        routerLinkActive="active"
        >{{ link.title }}</a
      >
    </li>
  </ul>

  <ng-content select="[avatar]"></ng-content>
</header>
