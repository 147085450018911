import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  NgZone,
  inject,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { GlobalErrorStateMatcher } from '../../misc/share';
import { startWith, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ui-input-errors]',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss'],
  standalone: true,
  imports: [CommonModule, MatInputModule],
  changeDetection: ChangeDetectionStrategy.Default, // Needed
})
export class InputErrorsComponent implements OnInit {
  el = inject(ElementRef);
  destroy = inject(DestroyRef);
  zone = inject(NgZone);

  @Input() fieldName?: string;
  @Input({ required: true }) control!: AbstractControl;
  @Input() regexMessage?: string;
  /**
   * Der marker ist nötig, wenn das mat-error element außerhalb eines
   * mat-form-fields verwendet wird. Denn das Form-field prüft selbst, wenn die Fehler angezeigt
   * werden soll. Wenn wir kein mat-form-field nutzen, muss die directive selbst entscheiden, ob diese
   * anhand der control angezeigt werden soll.
   */
  @Input() matField? = true;

  ngOnInit() {
    if (!this.matField) {
      this.control.statusChanges
        .pipe(switchMap(_ => this.zone.onStable))
        .pipe(startWith(this.control.status), takeUntilDestroyed(this.destroy))
        .subscribe(() => {
          const display = new GlobalErrorStateMatcher().isErrorState(this.control);
          this.el.nativeElement.style.display = display ? 'block' : 'none';
        });
    }
  }
}
