<mat-spinner *ngIf="dataSource.loading$ | async"></mat-spinner>

<div class="overflow">
  <ng-content></ng-content>
</div>

<mat-paginator
  [length]="dataSource.itemsLength$ | async"
  [pageSize]="dataSource.pageSize"
  showFirstLastButtons
  [pageSizeOptions]="[5, 10, 20]"
  class="mt-2 mb-6"
></mat-paginator>
