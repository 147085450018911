import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

const unitConversions = {
  MB: 1_000_000,
  GB: 1_000_000_000,
};

type Unit = keyof typeof unitConversions;

@Pipe({
  name: 'toUnit',
  standalone: true,
})
export class ToSizeUnitPipe implements PipeTransform {
  transform(value: number, unit: Unit = 'MB') {
    return `${(value / unitConversions[unit]).toFixed(2)} ${unit}`;
  }
}
