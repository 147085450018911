import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-na',
  templateUrl: './na.component.html',
  styleUrls: ['./na.component.scss'],
  imports: [CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NaComponent {
  validData = false;

  @Input() set data(data: unknown) {
    /**
     * 1. Check is valid data
     * 2. Check data is == 0
     */
    // this.validData = !!data || data === 0;
    this.validData = data !== null && data !== undefined;
  }
}
