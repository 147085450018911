import type { AfterViewInit } from '@angular/core';
import { DestroyRef, Directive, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

/**
 * Attention:
 * This directive triggers the control change directly
 * use with brain e.g in comparer
 */
@Directive({
  selector: '[uiDisableControl]',
  standalone: true,
})
export class DisableControlDirective implements AfterViewInit {
  readonly ngControl = inject(NgControl);
  readonly destroyRef = inject(DestroyRef);

  disabled$ = new BehaviorSubject<boolean>(false);

  @Input() set uiDisableControl(condition: boolean) {
    this.disabled$.next(condition);
  }

  ngAfterViewInit() {
    const control = this.ngControl?.control;
    if (control) {
      this.disabled$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(disable => {
        if (disable) {
          control?.disable();
        } else {
          control?.enable();
        }
      });
    }
  }
}
