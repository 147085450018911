import type { Route } from '@angular/router';
import { ROUTESLIST } from './routes';
import type { RouteString } from './routes.model';

const redirectTo: RouteString = '/dashboard';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo,
  },
  ...ROUTESLIST,
];
