import type { AbstractControl, FormControl } from '@angular/forms';
import { FormArray, FormGroup } from '@angular/forms';

type TypeMixedForm = FormGroup | FormArray;
type FormControlMap = { [key: string]: FormControl<unknown> };

export function controlMarkAsTouched(
  control: AbstractControl,
  options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
) {
  control?.updateValueAndValidity();
  control?.markAsTouched(options);
  control?.markAsDirty(options);
}

export function initialControlsMarkAsTouched(
  controls: FormControlMap,
  options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
) {
  if (!controls) return;
  Object.keys(controls).forEach(key => {
    const formControlKey = key as keyof typeof controls;
    const control = controls[formControlKey];
    if (control && control.value) {
      control.updateValueAndValidity();
      control.markAsTouched(options);
      control.markAsDirty(options);
    }
  });
}

export function formMarkAllAsTouched(
  form: TypeMixedForm,
  options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
) {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    if (control instanceof FormGroup || control instanceof FormArray) {
      formMarkAllAsTouched(control as TypeMixedForm, options);
      return;
    }
    if (control) controlMarkAsTouched(control);
  });
}
