import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private matSnackService: MatSnackBar) {}

  show(message: string, duration = 3000) {
    this.matSnackService.open(message, undefined, {
      duration,
    });
  }
}
