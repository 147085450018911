import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'map',
  standalone: true,
})
export class MapPipe implements PipeTransform {
  transform<T, C>(value: T | null, mapFunc: (value: T) => C): C {
    if (value !== null) {
      return mapFunc(value);
    } else {
      return value as unknown as C;
    }
  }
}
