import type { MatFormFieldDefaultOptions } from '@angular/material/form-field';
import type { ErrorStateMatcher } from '@angular/material/core';
import type { AbstractControl, FormControl } from '@angular/forms';
import type { ErrorHandler } from '@angular/core';
import { Injectable, InjectionToken, NgZone, inject } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { HttpParams } from '@angular/common/http';
import type {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import type { Observable } from 'rxjs';
import {
  ReplaySubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  throwError,
} from 'rxjs';
import type { Route } from '@angular/router';
import { faro } from '@grafana/faro-web-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies

/**
 * Typescipt helper
 */

// https://dev.to/maxime1992/implement-a-generic-oneof-type-with-typescript-22em
export type ValueOf<Obj> = Obj[keyof Obj];
export type OneOnly<Obj, Key extends keyof Obj> = {
  [key in Exclude<keyof Obj, Key>]+?: undefined;
} & Pick<Obj, Key>;

export type OneOfByKey<Obj> = { [key in keyof Obj]: OneOnly<Obj, key> };
export type OneOfType<Obj> = ValueOf<OneOfByKey<Obj>>;

export type ControlInterface<T> = { [K in keyof T]: FormControl<T[K]> };

/**
 * please make shure, that the interfaces from CSI is not marked with? e.g {key?: value}
 */
export type ControlInterfaceRequired<T> = { [K in keyof T]-?: FormControl<T[K]> };

// // Example usage
// type AorBorC = OneOfType<{
//   a: string;
//   b: string;
//   c: string;
// }>;

// // This now works!
// const a: AorBorC = {
//   a: 'a',
// };

// // This not works!
// const b: AorBorC = {
//   a: 'a',
//   b: 'b',
// };

type FormControlMap = { [key: string]: FormControl<unknown> };

type PrependSlash<S extends string | undefined> = S extends string
  ? S extends ''
    ? ''
    : `/${S}`
  : '';

export type RoutePath<R extends Route> = R extends { children: Array<infer S extends Route> }
  ? `${PrependSlash<R['path']>}${RoutePath<S>}`
  : PrependSlash<R['path']>;

/**
 * Func
 */

export const IS_DEVELOP_TOKEN = new InjectionToken<boolean>('isDevelop');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  returnError$ = new ReplaySubject<Error>();

  isDevelop = inject(IS_DEVELOP_TOKEN);

  constructor(
    private _toastService: ToastService,
    private _ngZone: NgZone
  ) {
    this.returnError$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(error => {
      if (error.message) {
        // this._toastService.show('Es gibt scheinbar Probleme auf unserer Seite.');
      }

      console.error(error);
    });
  }

  handleError(error: Error) {
    if (error instanceof Error) {
      faro.api?.pushError(error);
    }
    this.returnError$.next(error);
  }
}

@Injectable({ providedIn: 'root' })
export class ErrorIntercept implements HttpInterceptor {
  readonly _toastService = inject(ToastService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else if (Array.isArray(error.error)) {
          // fluent validation error
          errorMessage = error.error.map(o => o.errorMessage).join(', ');
        } else {
          // server-side error
          errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);

        // Human info.
        if (typeof error.error === 'string') {
          //this._toastService.show(`Error: ${error.status} - ${error.error}`);
        }
        return throwError(() => Error(errorMessage));
      })
    );
  }
}

export const MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'auto',
  subscriptSizing: 'dynamic', // for long error texts & better grid handling in forms
  color: 'primary',
};

export class GlobalErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: AbstractControl | null
    //form: FormGroupDirective | NgForm | null
  ) {
    return (control?.invalid && control?.dirty) ?? false;
  }
}

export const scrollToInvalidFormElement = async (ngZone: NgZone) => {
  await firstValueFrom(ngZone.onStable);
  document.querySelector('.ng-invalid')?.scrollIntoView({ behavior: 'smooth' });
};

export function pick<T>(obj: T, keys: string[]): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj as object).filter(([key]) => keys.includes(key))
  ) as Partial<T>;
}

export function objectEquals(x: any, y: any): boolean {
  'use strict';

  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }

  if (Array.isArray(x)) {
    x = x.sort();
    y = y.sort();
  }

  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(x);
  return (
    Object.keys(y).every(function (i) {
      return p.indexOf(i) !== -1;
    }) &&
    p.every(i => {
      return objectEquals(x[i], y[i]);
    })
  );
}

export function checkPreviousValues<T extends { ctrl?: FormControlMap }>(
  newForm: T,
  oldForm?: T
): void {
  const oldCtrl = oldForm?.ctrl;
  const newCtrl = newForm?.ctrl;
  if (oldCtrl && newCtrl) {
    Object.keys(newCtrl).forEach(key => {
      const formControlKey = key as keyof typeof oldCtrl;

      if (
        oldCtrl[formControlKey] &&
        oldCtrl[formControlKey].value !== oldCtrl[formControlKey].defaultValue
      ) {
        newCtrl[formControlKey].setValue(oldCtrl[formControlKey].value);
      }
    });
  }
}

/**
 * array of objects search
 * 
const meinArray = [
  { name: 'Max Mustermann', alter: 30, beruf: 'Entwickler' },
  { name: 'Anna Beispiel', alter: 25, beruf: 'Designer' },
  { name: 'Peter Muster', alter: 35, beruf: 'Manager' }
];

// Suche nach dem Namen in einem bestimmten Schlüssel
const suchErgebnis1 = sucheImArrayMitKeys(meinArray, 'Anna', ['name']);
console.log(suchErgebnis1);

// Suche nach dem Alter in mehreren Schlüsseln
const suchErgebnis2 = sucheImArrayMitKeys(meinArray, '35', ['alter', 'name']);
console.log(suchErgebnis2);

// Suche ohne spezifizierte Schlüssel (durchsucht alle Schlüssel)
const suchErgebnis3 = sucheImArrayMitKeys(meinArray, 'Entwickler');
console.log(suchErgebnis3);
 */

export function sucheImArrayMitKeys<T>(
  array: T[],
  suchWert: NonNullable<T[keyof T]>,
  keys?: Array<keyof T>
): T[] {
  const ergebnisse: T[] = [];

  function simplyfy(value: NonNullable<T[keyof T]>): string {
    return value.toString().toLocaleLowerCase();
  }

  for (let i = 0; i < array.length; i++) {
    if (keys && keys.length > 0) {
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j];
        const value = array[i][key];

        if (value && simplyfy(value).includes(simplyfy(suchWert))) {
          ergebnisse.push(array[i]);
          break; // Keine weiteren Schlüssel prüfen, sobald ein Treffer gefunden wurde
        }
      }
    } else {
      for (const key in array[i]) {
        const value = array[i][key];

        if (value && simplyfy(value).includes(simplyfy(suchWert))) {
          ergebnisse.push(array[i]);
          break; // Keine weiteren Schlüssel prüfen, sobald ein Treffer gefunden wurde
        }
      }
    }
  }

  return ergebnisse;
}

// https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
export function toIsoString(date: Date) {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num: number) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}

export function toIsoDate(date: Date) {
  const pad = function (num: number) {
    return (num < 10 ? '0' : '') + num;
  };

  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
}

export function groupBy<T>(data: T[], key: keyof T) {
  const group: { [key: string]: T[] } = {};
  data.forEach(tmp => {
    const keyValue: string = tmp[key] as string;
    group[keyValue] = group[keyValue] || [];
    group[keyValue].push(tmp);
  });
  return group;
}

export async function downloadFile(fileUrl: string, name: string) {
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error('File download returned no success status code: ' + response.status);
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);

    document.body.appendChild(link);
    link.click();
    link.remove();

    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error while downloading file:', error);
  }
}

export function printFile(fileUrl: string) {
  // create valid url
  const link = document.createElement('a');
  link.href = fileUrl;

  // print
  const iframe: HTMLIFrameElement = document.createElement('iframe');
  const pdfFrame = document.body.appendChild(iframe);
  pdfFrame.style.display = 'none';
  pdfFrame.onload = () => {
    pdfFrame.contentWindow?.print();
    // pdfFrame.remove(); // do not remove iframe for print call.
  };
  pdfFrame.src = link.href;
}

export function getKeyByValue<T extends object>(object: T, value: ValueOf<T>) {
  // find Jährlich by value Jaehrlich
  // export enum ZahlungsweiseEnum {
  //   'Jährlich' = 'Jaehrlich',
  //   'Halbjährlich' = 'Halbjaehrlich',
  //   'Vierteljährlich' = 'Vierteljaehrlich',
  //   'Monatlich' = 'Monatlich',
  // }

  return Object.keys(object).find(key => object[key as keyof T] === value);
}

export function distinctUntilChangedObj<T>() {
  return distinctUntilChanged<T>((a, b) => objectEquals(a, b));
}

export function isNumber(value?: string | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
}

export function objectToQueryParams<T extends Record<string, any>>(req: T): HttpParams {
  let params = new HttpParams();
  Object.keys(req).forEach(key => {
    const value = req[key as keyof T];
    if (value || value == 0) params = params.set(key, value.toString());
  });
  return params;
}

export function safelyGetKeyValue<T extends object, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export function removeNullOrEmptyProperties<T extends Record<string, any>>(obj: T): T {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined && value !== '') {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as T);
}
