<div class="label mb-2 font-bold"><ng-content></ng-content></div>

<div class="chip-grid">
  <mat-chip
    *ngFor="let chip of state$ | async; trackBy: trackByValue"
    (click)="toggle(chip.value)"
    [highlighted]="chip.selected"
    [disabled]="chip.disabled"
  >
    {{ chip.label }}
  </mat-chip>
</div>
