import type { AfterViewInit, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import type { Observable } from 'rxjs';
import { BehaviorSubject, ReplaySubject, debounceTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import type { Option } from '../select/select.component';
import { FormControl } from '@angular/forms';
import type { Filter } from '../search-filter/search-filter.component';

export enum SortOrderDirection {
  Ascending = 'Ascending',
  Descending = 'Descending',
}
export interface FilterRequest<TColumnEnum> {
  filterColumn?: TColumnEnum;
  filterValue?: string;
  orderDirection?: SortOrderDirection;
  orderBy?: TColumnEnum;
  limit?: number;
  pageNumber?: number;
  offset?: number;
  filterId?: string;
}

export interface ObjectCreatedResponse {
  id: string;
}

export interface InterfaceDataList<T> {
  items: T[];
  length: number;
}

export interface UpdateResult<T> {
  data: T;
}

export interface AnyError {
  error: Error;
}

export interface UpdateResultError<T> extends UpdateResult<T>, AnyError {}

export interface InterfaceOwnDataSource<T, TColumnEnum> {
  fetch$(filterRequest: FilterRequest<TColumnEnum>): Observable<InterfaceDataList<T>>;
}

export interface TableDataService<T, C, TColumnEnum>
  extends InterfaceOwnDataSource<T, TColumnEnum> {
  refresh$: Observable<boolean>;

  asOptions$(): Observable<Option<string>[]>;

  add(data: C): Observable<ObjectCreatedResponse>;

  find$(ids: string[]): Observable<T[]>;

  all$(): Observable<T[]>;

  remove$(data: T): Observable<T>;
}

export class OwnDataSource<T, C, TColumnEnum> implements DataSource<T> {
  pageSize = 10;
  dataSubject$ = new BehaviorSubject<T[]>([]);
  loadingSubject$ = new BehaviorSubject<boolean>(false);
  itemsLength$ = new BehaviorSubject<number>(0);
  loading$ = this.loadingSubject$.asObservable();
  refreshData$ = new ReplaySubject(1);
  filterRequest: FilterRequest<TColumnEnum> = {
    limit: this.pageSize,
    offset: 0,
  };

  constructor(private dataService: InterfaceOwnDataSource<T, TColumnEnum>) {}

  connect(collectionViewer: CollectionViewer) {
    return this.dataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer) {
    this.dataSubject$.complete();
    this.loadingSubject$.complete();
  }

  load(filterRequest: FilterRequest<TColumnEnum> = this.filterRequest) {
    this.loadingSubject$.next(true);

    this.dataService.fetch$(filterRequest).subscribe(list => {
      this.loadingSubject$.next(false);
      this.dataSubject$.next(list.items);
      this.itemsLength$.next(list.length);
    });
  }
}

@Component({
  selector: 'ui-table',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
  ],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T, C, TColumnEnum> implements OnInit, AfterViewInit {
  @Input() dataSource!: OwnDataSource<T, C, TColumnEnum>;
  @Input() sortRef?: MatSort;
  @Input() searchControl?: FormControl<Filter<TColumnEnum> | null>;
  @Input() filterId?: string;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  private _destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.refreshData$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(res => {
      this.paginator?.firstPage();
      this.loadData();
    });

    this.searchControl?.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef), debounceTime(300))
      .subscribe(() => {
        this.paginator?.firstPage();
        this.loadData();
      });

    /**
     * On sort change, set page to first
     * load fresh data
     */
    this.sortRef?.sortChange
      .asObservable()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.paginator?.firstPage();
        this.loadData();
      });

    /**
     * On page change, load fresh data
     */
    this.paginator?.page
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.loadData());

    /**
     * Translate label to German
     */
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = 'Anzahl pro Seite';
    }
  }

  loadData() {
    const filterRequest: FilterRequest<TColumnEnum> = {
      filterValue: this.searchControl?.value?.filter,
      filterColumn: this.searchControl?.value?.column,
      orderDirection:
        (this.sortRef?.direction === 'asc'
          ? SortOrderDirection.Ascending
          : SortOrderDirection.Descending) ?? SortOrderDirection.Ascending,
      limit: this.paginator?.pageSize ?? 10,
      pageNumber: Number(this.paginator?.pageIndex) || 0,
      offset: (Number(this.paginator?.pageSize) || 0) * (Number(this.paginator?.pageIndex) || 0),
      orderBy: <TColumnEnum | undefined>this.sortRef?.active,
      filterId: this.filterId,
    };
    this.dataSource?.load(filterRequest);
  }
}
