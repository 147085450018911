<div mat-dialog-content>
    <div class="flex flex-col w-full gap-6 items-center justify-center text-center">
        <img class="h-64 2xl:h-80" src="../../assets/images/update-app.svg">
        <ui-headline>
            <span class="text-2xl 2xl:text-3xl font-medium">Neue Version verfügbar</span>
        </ui-headline>

        <p>Bitte aktualisiere jetzt die {{data?.appName || ''}} App, damit du sie weiterhin
            <br />uneingeschränkt nutzen kannst.
        </p>
    </div>
</div>
<div mat-dialog-actions>
    <div class="flex w-full items-center justify-center">
        <button mat-flat-button mat-dialog-close class="btn-primary-lg" color="accent">
            Jetzt aktualisieren</button>
    </div>
</div>