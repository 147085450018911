import type { OverlayRef } from '@angular/cdk/overlay';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import type { ComponentRef } from '@angular/core';
import { Injectable, InjectionToken, Injector, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter, take, tap, timer } from 'rxjs';
import { SnackbarComponent } from '../lib/snackbar/snackbar.component';

export const SNACKBAR_DATA = new InjectionToken<Array<Snackbar>>('SNACKBAR_DATA');

export interface Snackbar {
  message: string;
  type: SnackbarTypes;
  id?: number;
  duration?: number;
  position?: Position;
  disableTimeout?: boolean;
  overlayRef?: OverlayRef;
}

export type SnackbarTypes = 'success' | 'error' | 'info' | 'warning';

export type Position = 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left';
interface Config {
  id?: number;
  duration?: number;
  position?: Position;
  disableTimeout?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private _id = 0;
  private _router = inject(Router);
  private _overlay = inject(Overlay);
  private _injector = inject(Injector);
  private _overlayGroups: Map<Position, OverlayRef> = new Map();
  private _componentRefs: Map<Position, ComponentRef<SnackbarComponent>> = new Map();

  alerts$ = new BehaviorSubject<Array<Snackbar>>([]);

  constructor() {
    this.clearAlertsOnRouteChange$.subscribe();
  }

  private clearAlertsOnRouteChange$ = this._router.events.pipe(
    filter(evt => evt instanceof NavigationStart),
    tap(() => this.closeAll())
  );

  error(message: string, configs?: Config) {
    this.open({ message, type: 'error', ...configs });
  }

  success(message: string, configs?: Config) {
    this.open({ message, type: 'success', ...configs });
  }

  warning(message: string, configs?: Config) {
    this.open({ message, type: 'warning', ...configs });
  }

  info(message: string, configs?: Config) {
    this.open({ message, type: 'info', ...configs });
  }

  close(alertId?: number, position: Position = 'bottom-left') {
    if (alertId === undefined || alertId === null) return;
    const updated = [...this.alerts$.getValue()].filter(item => item.id !== alertId);
    this.alerts$.next(updated);
    this._componentRefs
      .get(position)
      ?.instance.updateData(this.alerts$.getValue().filter(alert => alert.position === position));
    if (!this.alerts$.value.length) this._overlayGroups.get(position)?.dispose();
  }

  closeAll() {
    this.alerts$.next([]);
    this._overlayGroups.forEach(overlayRef => overlayRef.dispose());
    this._overlayGroups.clear();
  }

  private open({ position = 'bottom-left', ...props }: Snackbar): number | undefined {
    const alertExists = this.alerts$.getValue().find(item => item.message === props.message);
    if (alertExists) return alertExists.id;

    const alertId = props.id || this._id++;
    if (this._overlayGroups.get(position)) this._overlayGroups.get(position)?.dispose();

    const overlayRef = this.createOverlay(position);

    const updated = [...this.alerts$.getValue(), { ...props, id: alertId, position: position }];
    this.alerts$.next(updated);

    this.attachComponentToOverlay(overlayRef, position);

    if (!props.disableTimeout) {
      timer(props.duration ?? 5 * 1000)
        .pipe(take(1))
        .subscribe(() => {
          this.close(alertId, position);
        });
    }

    return alertId;
  }

  private createOverlay(position: Position): OverlayRef {
    const config = new OverlayConfig();
    config.positionStrategy = this.getPositionStrategy(position);

    const overlayRef = this._overlay.create(config);
    if (overlayRef) this._overlayGroups.set(position, overlayRef);

    return overlayRef;
  }

  private attachComponentToOverlay(overlayRef: OverlayRef, position: Position) {
    const injector = Injector.create({
      providers: [
        {
          provide: SNACKBAR_DATA,
          useValue: this.alerts$.getValue().filter(alert => alert.position === position),
        },
      ],
      parent: this._injector,
    });

    if (!overlayRef) return 0;

    const componentRef = overlayRef.attach(new ComponentPortal(SnackbarComponent, null, injector));
    if (componentRef) this._componentRefs.set(position, componentRef);

    return componentRef;
  }

  private getPositionStrategy(position: Position) {
    const offset = this.getOffsetForPosition(position);
    const [vertical, horizontal] = position.split('-') as ['top' | 'bottom', 'left' | 'right'];

    return this._overlay.position().global()[horizontal](offset.x)[vertical](offset.y);
  }

  private getOffsetForPosition(position: Position) {
    return {
      x: '10px',
      y: position.includes('top') ? '40px' : '20px',
    };
  }
}
