<ui-header class="mb-10" [links]="links$ | async">
  <ng-container ngProjectAs="'[logo]'">
    <ng-container *ngIf="headerLogo$ | async as headerLogo; else cpLogo">
      <div class="flex items-center justify-center gap-2">
        <a [routerLink]="''">
          <ng-container [ngSwitch]="headerLogo.produkt">
            <img
              *ngSwitchCase="'Lebensversicherung'"
              class="w-[126px] h-[40px]"
              src="/assets/produkt/lv.svg"
              alt="LV"
            />
            <img
              *ngSwitchCase="'Krankenversicherung'"
              class="w-[126px] h-[40px]"
              src="/assets/produkt/kv.svg"
              alt="KV"
            />
            <img
              *ngSwitchCase="'Sachversicherung'"
              class="w-[126px] h-[40px]"
              src="/assets/produkt/sach.svg"
              alt="SACH"
            />
          </ng-container>
        </a>
        <span>{{ headerLogo.sparte }}</span>
      </div>
    </ng-container>
    <ng-template #cpLogo>
      <div class="flex items-center justify-center">
        <a [routerLink]="''">
          <img class="w-[126px]" src="/assets/logo.svg" alt="Company Logo" />
        </a>
      </div>
    </ng-template>
  </ng-container>

  <ng-container ngProjectAs="[avatar]">
    <comparit-avatar-user *ngIf="keycloakService.isLoggedIn()"></comparit-avatar-user>
  </ng-container>
</ui-header>

<div class="page-container">
  <router-outlet></router-outlet>
</div>
