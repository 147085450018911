import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  get selectedProdukt(): string {
    const produkt = window.localStorage.getItem('produkt');
    return produkt ?? '';
  }

  set selectedProdukt(produktChange) {
    window.localStorage.setItem('produkt', produktChange);
    this.selectedProdukt$.next(produktChange);
  }

  selectedProdukt$ = new BehaviorSubject<string>(this.selectedProdukt);

  get selectedSparte(): string {
    const sparte = window.localStorage.getItem('sparte');
    return sparte ?? '';
  }

  set selectedSparte(sparteChange) {
    window.localStorage.setItem('sparte', sparteChange);
    this.selectedSparte$.next(sparteChange);
  }

  selectedSparte$ = new BehaviorSubject<string>(this.selectedSparte);
}
