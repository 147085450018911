<nav mat-tab-nav-bar [mat-stretch-tabs]="false" [tabPanel]="tabPanel">
  <a
    mat-tab-link
    *ngFor="let link of links; trackBy: trackById"
    [routerLink]="link.url ? link.url : ''"
    [routerLinkActiveOptions]="routerLinkActiveOptions"
    routerLinkActive="active"
    #rla="routerLinkActive"
    [active]="rla.isActive"
  >
    {{ link.title }}
  </a>
</nav>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
