<ng-template #loading>
  <mat-spinner [diameter]="20"></mat-spinner>
</ng-template>

<ng-template #onlyForm>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="enabled; else onlyForm">
  <div
    [class.padding]="padding"
    [class.pending]="loading$ | async"
    [class.show-actions-on-hover]="enableReadonlyMode"
    *ngIf="{ edit: editStatus$ | async, changed: valueChanged$ | async } as state"
  >
    <form [formGroup]="fg" (submit)="doSave()" novalidate>
      <ng-container *ngIf="readChild; else onlyForm">
        <ng-container *ngIf="state.edit === false; else onlyForm">
          <ng-container *ngTemplateOutlet="readChild"></ng-container>
        </ng-container>
      </ng-container>
      <div [class.force-show-actions]="state.changed" class="actions flex justify-end items-center">
        <ng-template #actions>
          <button type="button" (click)="readonly()" *ngIf="!state.edit">
            <mat-icon svgIcon="cp-edited"></mat-icon>
          </button>
          <button type="button" (click)="cancel()" *ngIf="state.edit">
            <mat-icon svgIcon="cp-close"></mat-icon>
          </button>
          <button type="button" (click)="doSave()" *ngIf="state.edit">
            <mat-icon svgIcon="cp-check"></mat-icon>
          </button>
        </ng-template>
        <ng-container *ngIf="loading$ | async; else actions">
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>
      </div>
    </form>
  </div>
</ng-container>
