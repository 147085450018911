<ng-template #errorsTemplate let-errors="errors">
  <ng-container *ngIf="errors">
    <ng-container *ngIf="errors.pattern">
      <ng-template #abstractMessage>
        <ng-container> Die Eingabe enthält ein unzulässiges Zeichen. </ng-container>
      </ng-template>
      <span *ngIf="regexMessage; else abstractMessage" [innerHTML]="regexMessage"></span>
    </ng-container>

    <ng-container *ngIf="errors.required"> Bitte ergänzen Sie das Feld. </ng-container>

    <ng-container *ngIf="errors.minlength">
      Bitte ergänzen Sie dieses Feld mit einer Mindestlänge von
      {{ errors.minlength.requiredLength }} Zeichen.
    </ng-container>

    <ng-container *ngIf="errors.maxlength">
      Das Feld {{ fieldName }} besitzt eine maximale Länge von
      {{ errors.maxlength.requiredLength }} Zeichen.
    </ng-container>

    <ng-container *ngIf="errors.min">
      Das Feld {{ fieldName }} erfordert ein min. Wert von {{ errors.min.min }}.
    </ng-container>

    <ng-container *ngIf="errors.max">
      Das Feld {{ fieldName }} hat den maximalen Wert von {{ errors.max.max }} überschritten.
    </ng-container>

    <ng-container *ngIf="errors.email">
      Das Feld {{ fieldName }} muss eine gültige E-Mail-Adresse enthalten.
    </ng-container>

    <ng-container *ngIf="errors.number">
      Das Feld {{ fieldName }} sollte eine Zahl sein.
    </ng-container>

    <ng-container *ngIf="errors.url">
      Die Eingabe {{ fieldName }} sollte eine URL sein.
    </ng-container>

    <ng-container *ngIf="errors.minStartOfContractDate">
      Der Vertragsbeginn kann maximal 2 Monate rückdatiert werden.
    </ng-container>

    <ng-container *ngIf="errors.minDate">
      Das Datum muss am oder nach dem
      {{ errors.minDate?.value | date }} liegen.
    </ng-container>

    <ng-container *ngIf="errors.maxDate">
      Das Datum muss am oder vor dem
      {{ errors.maxDate?.value | date }} liegen.
    </ng-container>

    <ng-container *ngIf="errors.matDatepickerFilter">
      Das ausgewählte Datum ist nicht zulässig. Bitte wählen Sie ein anderes Datum.
    </ng-container>

    <ng-container *ngIf="errors.date">
      Das Feld {{ fieldName }} sollte ein Datum sein (TT.MM.JJJJ).
    </ng-container>

    <ng-container *ngIf="errors.userExists"> Der Nutzer ist nicht vorhanden. </ng-container>

    <ng-container *ngIf="errors.uniqueQuantity">
      Es gibt bereits eine Anzahloption mit der gleichen Menge.
    </ng-container>

    <ng-container *ngIf="errors.contactInformationRequired">
      Bitte ergänze das Feld. Bitte gib mindestens eine Kontaktinformation an.
    </ng-container>
    <ng-container *ngIf="errors.invalidPhoneNumber">
      Bitte gib eine gültige Telefonnummer ein. Gültig sind nur die folgenden Zeichen: "+", "0-9".
    </ng-container>
    <ng-container *ngIf="errors.incompletePhoneNumber">
      Bitte gib eine vollständige Telefonnummer ein. Erforderlich sind min. 6 Zeichen.
    </ng-container>
    <ng-container *ngIf="errors.invalidPostalCode">
      Bitte gib eine gültige Postleitzahl ein. Sie muss genau 5 Ziffern enthalten.
    </ng-container>
    <ng-container *ngIf="errors.notMatch"> Die Eingabe stimmt nicht überein. </ng-container>
    <ng-container *ngIf="errors.iban"> Ungültige IBAN. </ng-container>
    <ng-container *ngIf="errors.custom">
      {{ errors.custom }}
    </ng-container>
    <ng-container *ngIf="errors.firstDayOfYear">
      Das Datum darf nicht am 01.01. liegen
    </ng-container>
  </ng-container>
</ng-template>

<ng-container *ngTemplateOutlet="errorsTemplate; context: { errors: control.errors }"></ng-container>

<!--dirty: {{ control.dirty }} - -->
<!--invalid: {{ control.invalid }}  - -->
<!--touched: {{ control.touched }} - -->