<div class="dropbox" #dropbox *ngIf="state$ | async as state" [class.dragging]="state.dragging"
  [class.disabled]="state.disabled">
  <input type="file" #fileInput id="fileInput" (change)="onFileInputChange($event)" [disabled]="state.disabled"
    [accept]="state.mediaTypes" />
  <ng-content></ng-content>
  <div class="flex justify-center items-center" *ngIf="state.value">
    <span>{{ state.value.name }} ({{ state.value.size | toUnit: 'MB'}})</span>
    <button [disabled]="state.disabled" (click)="removeFile()" mat-icon-button color="warn"
      matTooltip="ausgewählte Datei entfernen">
      <mat-icon svgIcon="cp-close-filled"></mat-icon>
    </button>
    <ng-container *ngIf="state.mediaTypes?.includes(fileType.svg)">
      <ng-container *ngIf="value$ | async | map: getImagePreview | async as data">
        <div class="image-preview">
          <img class="w-10 h-auto" [src]="'data:' + fileType.svg + ';base64,' + data" />
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="!state.value">
    <button mat-flat-button class="btn-secondary-sm" (click)="fileInput.click()">{{ label }}</button>
  </ng-container>
  <ng-container *ngIf="maxFileSizeMb">
    <small class="text-cp-primary-60">max. {{ maxFileSizeMb }} MB Dateigröße</small>
  </ng-container>
</div>

<ng-container *ngIf="uploadInfo$ | async as uploadInfo">
  <div class="flex flex-col gap-2 mt-4">
    <div *ngIf="uploadInfo.status" class="flex flex-col gap-2">
      <small [ngClass]="uploadInfo.status === 'error' ? 'text-cp-error-100' : 'text-cp-primary-60'">
        {{uploadInfo.status === 'error' ? 'Hochladen fehlgeschlagen' : 
        uploadInfo.progress === 100 ? 'Dokument ist hochgeladen' : 'Dokument wird hochgeladen..'}}</small>
      <div class="flex items-center gap-2">
        <mat-progress-bar [color]="uploadInfo.status === 'error' ? 'warn' : 'accent'"
          [value]="uploadInfo.progress"></mat-progress-bar>
        <ng-container *ngIf="uploadInfo.status">
          <button *ngIf="uploadInfo.status === 'progress'" class="min-rounded-button" (click)="cancelUpload.next(true)"
            mat-icon-button>
            <mat-icon svgIcon="cp-close"></mat-icon>
          </button>
          <button *ngIf="uploadInfo.status === 'error'" class="min-rounded-button" (click)="resetUpload.next(true)"
            mat-icon-button>
            <mat-icon svgIcon="cp-reset"></mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <div *ngIf="uploadInfo.uploadedDocuments?.length" class="flex flex-col gap-2">
      <small class="text-cp-primary-60">Hochgeladene Dokumente</small>
      <div class="flex justify-between" *ngFor="let document of uploadInfo.uploadedDocuments">
        <strong>{{document.name}}</strong>
        <button class="min-rounded-button" (click)="deleteDocument.next(document.id)" mat-icon-button>
          <mat-icon svgIcon="cp-close"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <ui-divider />
</ng-container>