<button
  class="filterButton"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (click)="clearInput(); focusInput(filterInput); $event.stopPropagation()"
>
  <mat-icon svgIcon="cp-filter"></mat-icon>
</button>
<mat-menu class="formFieldWithoutPadding" #menu="matMenu">
  <mat-form-field (click)="$event.stopPropagation()">
    <mat-icon matPrefix svgIcon="cp-search"></mat-icon>
    <input #filterInput [formControl]="searchControl" type="text" matInput placeholder="Filter" />
  </mat-form-field>
</mat-menu>
