<mat-form-field *ngIf="control">
  <mat-label *ngIf="label$ | async as label">{{ label }}</mat-label>
  <mat-select multiple [formControl]="control" *ngIf="multiSelect$ | async; else singleSelect">
    <div class="dropdown-list">
      <mat-form-field *ngIf="(searchable$ | async) && (filterInput$ | async)" class="mb-4">
        <div class="search-grid">
          <mat-icon svgIcon="cp-search"></mat-icon>
          <input matInput [formControl]="searchQuery" (keydown)="$event.stopPropagation()" />
          <mat-icon
            *ngIf="searchQueryResettable$ | async"
            svgIcon="cp-close"
            (click)="clearSearchQuery()"
          ></mat-icon>
        </div>
      </mat-form-field>
      <div class="dropdown-items">
        <mat-option
          *ngFor="let option of optionsWithVisibilityStatus$ | async; trackBy: trackByValue"
          [value]="option.value"
          [class.hidden]="!option.visible"
          class="!py-2 h-auto"
          >{{ option.label }}</mat-option
        >
      </div>
      <div class="mt-4">
        <a (click)="deselectAll()" *ngIf="allSelected$ | async; else notAllSelected"
          >Alle abwählen</a
        >
        <ng-template #notAllSelected><a (click)="selectAll()">Alle auswählen</a></ng-template>
      </div>
    </div>
  </mat-select>
  <ng-template #singleSelect>
    <mat-select [formControl]="control">
      <div class="dropdown-list">
        <ng-content select="[masterSearch]"></ng-content>
        <mat-form-field *ngIf="(searchable$ | async) && (filterInput$ | async)">
          <div class="search-grid">
            <mat-icon svgIcon="cp-search"></mat-icon>
            <input matInput [formControl]="searchQuery" (keydown)="$event.stopPropagation()" />
            <mat-icon
              *ngIf="searchQueryResettable$ | async"
              svgIcon="cp-close"
              (click)="clearSearchQuery()"
            ></mat-icon>
          </div>
        </mat-form-field>
        <div class="dropdown-items">
          <mat-option *ngIf="!(required || searchQuery.value)"></mat-option>
          <mat-option
            *ngFor="let option of optionsWithVisibilityStatus$ | async; trackBy: trackByValue"
            [value]="option.value"
            class="!py-2 h-auto"
            [class.hidden]="!option.visible"
            >{{ option.label }}</mat-option
          >
        </div>
      </div>
    </mat-select>
  </ng-template>
</mat-form-field>
