<div *ngFor="let alert of data$ | async; trackBy: trackById"
    class="flex justify-between py-4 my-2 min-w-80 w-fit px-6 gap-3 text-white rounded"
    [ngClass]="backgroundColor[alert.type]">
    <div class="flex gap-3">
      <mat-icon *ngIf="alert.type !== 'success'" svgIcon="cp-info" class="stroke-none"></mat-icon>
      <mat-icon *ngIf="alert.type === 'success'" svgIcon="cp-check" class="stroke-white"></mat-icon>

      <span>{{ alert.message }}</span>
    </div>
    <mat-icon (click)="snackbarService.close(alert.id, alert.position)" class="stroke-white cursor-pointer"
      svgIcon="cp-close"></mat-icon>
</div>
